
import { Options, Vue } from "vue-class-component";
import Header from "./components/layout/Header.vue";
import Footer from "./components/layout/Footer.vue";
import HelpTooltip from "./components/layout/HelpTooltip.vue";
import Alert from "@/shared/components/Alert.vue";
import MaintenancePage from "@/views/Maintenance.vue";

@Options({
  components: {
    MaintenancePage,
    Header,
    Footer,
    HelpTooltip,
    Alert,
  },
})
export default class App extends Vue {
  maintenanceMode = false;

  async checkApiHealth() {
    const url = `https://api.wem.sk/actuator/health`;
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 6000); // 6 seconds timeout

    try {
      const response = await fetch(url, { signal: controller.signal });
      clearTimeout(timeoutId); // Clear the timeout if the request succeeds
      const data = await response.json();

      if (response.ok && data.status === 'UP') {
        this.maintenanceMode = false;
      } else {
        this.maintenanceMode = true;
        console.error("API health check failed:", data);
      }
    } catch (error) {
      this.maintenanceMode = true;
      console.error("Error checking API health:", error);
    }
  }

  startApiHealthCheck() {
    this.checkApiHealth(); // Initial check
    setInterval(() => this.checkApiHealth(), 30000); // Repeat every 30 seconds
  }

  mounted() {
    this.startApiHealthCheck();
  }
}
